import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import classNames from "classnames";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { eventLocation } from "@circle-react/helpers/postHelpers";
import { Button } from "@circle-react-uikit/Button";
import "./styles.scss";

const TARGET_ZOOM = 15;

const libraries = ["places"];
const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  scrollWheel: false,
  fullscreenControl: false,
  panControl: false,
  rotateControl: false,
  gestureHandling: "none",
  clickableIcons: false,
  controlSize: 25,
  disableDoubleClickZoom: true,
  draggableCursor: "arrow",
  draggingCursor: "arrow",
  keyboardShortcuts: false,
};
const autocompleteOptions = {
  fields: [
    "place_id",
    "name",
    "formatted_address",
    "geometry.location",
    "url",
    "website",
  ],
};

const locationFromPlace = place => {
  if (place?.geometry) {
    return {
      lat: isFunction(place.geometry.location.lat)
        ? place.geometry.location.lat()
        : place.geometry.location.lat,
      lng: isFunction(place.geometry.location.lng)
        ? place.geometry.location.lng()
        : place.geometry.location.lng,
    };
  }
  return null;
};

const serializePlace = place => {
  if (place) {
    return JSON.stringify(place);
  }
  return "";
};

const Picker = ({
  name,
  onChange,
  value,
  placeholder,
  className = "form-control",
  autoFocus,
  ...rest
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: window.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [currentPlace, setCurrentPlace] = useState(eventLocation(value));
  const [autocompleteBox, setAutocompleteBox] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const searchInputRef = useRef();

  // Change value when place changes
  useEffect(() => {
    const newValue = serializePlace(currentPlace);
    if (newValue !== value) {
      onChange(newValue);
    }
  }, [currentPlace]);

  // Be aware of value change from the outside
  useEffect(() => {
    setCurrentPlace(eventLocation(value));
  }, [value]);

  const onAutocompleteLoad = useCallback(
    autocompleteInstance => setAutocompleteBox(autocompleteInstance),
    [],
  );

  const validatePlace = place => !!place?.formatted_address;

  const onPlaceChanged = () => {
    const place = autocompleteBox.getPlace();
    if (validatePlace(place)) {
      setCurrentPlace(place);
    } else {
      setCurrentPlace(null);
    }
    setIsEditing(false);
  };

  const onEdit = evt => {
    evt.preventDefault();
    setIsEditing(true);
    setCurrentPlace(null);
  };

  const onKeyPress = useCallback(evt => {
    if (evt.key === "Enter") {
      evt.preventDefault();
    }
  }, []);

  return isLoaded ? (
    <div className="location-picker">
      <input type="hidden" name={name} value={value} {...rest} />
      {!currentPlace && (
        <Autocomplete
          onLoad={onAutocompleteLoad}
          onPlaceChanged={onPlaceChanged}
          options={autocompleteOptions}
        >
          <input
            type="text"
            placeholder={placeholder}
            className={classNames(
              "placeholder:text-light placeholder:opacity-100",
              className,
            )}
            autoFocus={autoFocus || isEditing}
            onKeyPress={onKeyPress}
            ref={searchInputRef}
          />
        </Autocomplete>
      )}
      {currentPlace && (
        <>
          <GoogleMap
            mapContainerClassName="map-container"
            zoom={TARGET_ZOOM}
            options={mapOptions}
            center={locationFromPlace(currentPlace)}
          >
            {currentPlace && (
              <Marker
                cursor="arrow"
                position={locationFromPlace(currentPlace)}
              />
            )}
          </GoogleMap>
          <div className="location-picker__location">
            <div className="info">
              {currentPlace.name && <p className="name">{currentPlace.name}</p>}
              {currentPlace.formatted_address && (
                <p>{currentPlace.formatted_address}</p>
              )}
            </div>
            <Button variant="secondary" onClick={onEdit}>
              {t("location_picker.edit")}
            </Button>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export const MemoPicker = memo(Picker);
