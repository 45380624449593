import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { compact, flatten } from "lodash";
import { useCommunityMemberSearch } from "@circle-react/hooks/useCommunityMemberSearch";
import { Loader } from "@circle-react-uikit/Loader";
import { Select } from "@circle-react-uikit/Select";

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

interface CommunityMemberSelectProps
  extends React.ComponentProps<typeof Select> {
  valueField?: "id" | "user_id";
  addable?: boolean;
  placeholder?: string;
  excludeAdmins?: boolean;
  includeInactive?: boolean;
  filters?: Record<string, any>;
  shouldFilterSelectedItems?: boolean;
  getCommunityMemberText?: (communityMember: { name: string }) => string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const i18nRoot = "community_member_select";

export const CommunityMemberSelect = ({
  valueField = "id",
  addable,
  placeholder,
  excludeAdmins = false,
  includeInactive = false,
  filters = {},
  shouldFilterSelectedItems = false,
  getCommunityMemberText = ({ name }) => name,
  ...passThroughProps
}: CommunityMemberSelectProps) => {
  const { searchCommunityMembers, communityMembers, resetCommunityMembers } =
    useCommunityMemberSearch();

  const { value, defaultValue } = passThroughProps;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const preloadValues = compact(flatten([defaultValue, value]));
    if (preloadValues.length) {
      searchCommunityMembers({
        filters: {
          [valueField]: preloadValues,
          exclude_admins: excludeAdmins,
          include_inactive: includeInactive,
          ...filters,
        },
      })
        .then(() => setIsLoading(false))
        .catch(error => {
          console.error(error);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const onSearch = async (query: any) => {
    if (!query) {
      resetCommunityMembers();
      return;
    }
    return searchCommunityMembers({
      filters: {
        exclude_admins: excludeAdmins,
        include_inactive: includeInactive,
        ...filters,
      },
      per_page: 10,
      query,
    });
  };

  const data = communityMembers.map(communityMember => ({
    value: communityMember[valueField],
    text: getCommunityMemberText(communityMember),
  }));
  let getAddable;
  let notFoundContent = t([i18nRoot, "no_matches_found"]);

  if (addable) {
    notFoundContent = t([i18nRoot, "no_matches_found_try_using_email"]);
    getAddable = (value: any) => {
      if (value.match(EMAIL_REGEX)) {
        return {
          value,
          text: value,
          listItemText: (
            <div className="text-dark">
              {t("invite")} {value}
            </div>
          ),
        };
      }
      return undefined;
    };
  }

  const placeholderWithFallback =
    placeholder || t([i18nRoot, "start_typing_a_name"]);

  return (
    <Select
      {...passThroughProps}
      notFoundContent={notFoundContent}
      addable={getAddable}
      data={data}
      onSearch={onSearch}
      minSearchValueLength={1}
      placeholder={placeholderWithFallback}
      shouldFilterSelectedItems={shouldFilterSelectedItems}
    />
  );
};
