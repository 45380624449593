import type { ComponentPropsWithoutRef, MouseEvent, Ref } from "react";
import classnames from "classnames";
import { isFunction } from "lodash";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

type HTMLButton = ComponentPropsWithoutRef<"button">;

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "secondary-light"
  | "tertiary"
  | "plain"
  | "text"
  | "danger"
  | "danger-outline"
  | "circle"
  | "circle-plain"
  | "circle-text"
  | "marketing-gradient"
  | "link";

export interface ButtonProps extends HTMLButton {
  full?: boolean;
  small?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  isFullRounded?: boolean;
  forwardedRef?: Ref<HTMLButtonElement>;
  variant?: ButtonVariant;
  onClick?: (
    event: MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void> | Promise<unknown>;
}

export const Button = ({
  variant = "primary",
  children,
  full,
  small,
  extraLarge,
  isFullRounded: isFullRoundedProp = false,
  large,
  className,
  forwardedRef,
  type = "submit",
  onClick,
  ...rest
}: ButtonProps) => {
  const { isV3Enabled } = useIsV3();
  const isFullRounded = isFullRoundedProp || isV3Enabled;

  return (
    <button
      {...rest}
      // eslint-disable-next-line react/button-has-type -- False positive
      type={type}
      ref={forwardedRef}
      className={classnames(
        "focus-visible:!outline-secondary font-bold transition-colors duration-200 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2 disabled:cursor-not-allowed",
        {
          "px-8 py-3": extraLarge,
          "px-6 py-2": large,
          "px-[18px] py-[6px] text-sm": !large && !small && !extraLarge,
          "px-2 py-1 text-xs": small,
          "w-full": full,
          "rounded-md": !isFullRounded,
          "rounded-full": isFullRounded,
          "bg-brand text-brand-button disabled:bg-disabled transition-opacity hover:opacity-90":
            variant === "primary",
          "border-secondary bg-primary text-darkest hover:bg-tertiary disabled:border-hover disabled:bg-primary disabled:text-light border":
            variant === "secondary",
          "border-hover bg-primary text-darkest hover:bg-secondary disabled:text-light border":
            variant === "secondary-light",
          "text-default hover:bg-tertiary disabled:border-hover disabled:text-light border-transparent":
            variant === "tertiary",
          "text-link disabled:text-light bg-transparent": variant === "plain",
          "border-v2-danger bg-v2-danger disabled:bg-disabled disabled:border-disabled border text-white transition-opacity hover:opacity-90":
            variant === "danger",
          "border-v2-danger bg-primary text-v2-danger disabled:bg-disabled border transition-opacity hover:opacity-90":
            variant === "danger-outline",
          "bg-gradient-to-tl from-[#471AFF] to-[#509AF0] text-white hover:opacity-90 disabled:opacity-50":
            variant === "marketing-gradient",
          "border-circle-button bg-circle-button text-circle-button hover:border-circle-button-hover hover:bg-circle-button-hover disabled:border-disabled disabled:bg-disabled border":
            variant === "circle",
          "disabled:text-light text-default hover:text-darkest bg-transparent !p-0 font-medium":
            variant === "text",
          "hover:underline": variant === "link",
          "hover:bg-tertiary !font-semibold": variant === "circle-plain",
          "text-circle !p-0 !font-semibold": variant === "circle-text",
          "flex h-10 items-center justify-center text-sm": large && isV3Enabled,
        },
        className,
      )}
      onClick={async event => {
        if (isFunction(onClick)) {
          await onClick(event);
        }
      }}
    >
      {children}
    </button>
  );
};
