import { forwardRef } from "react";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import type { ButtonProps } from "@circle-react-uikit/Button";

export interface ModalBackButtonProps extends ButtonProps {
  onClick: () => void;
}

export const ModalBackButton = forwardRef<
  HTMLButtonElement,
  ModalBackButtonProps
>(({ onClick, ...rest }, ref) => (
  <Button
    forwardedRef={ref}
    type="button"
    variant="plain"
    small
    onClick={onClick}
    data-testid="modal-back-button"
    {...rest}
  >
    <span className="sr-only">{t("back")}</span>
    <Icon className="text-dark" type="20-back" size={20} />
  </Button>
));

ModalBackButton.displayName = "ModalBackButton";
