import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import uniqueId from "lodash/uniqueId";
import { Button } from "@circle-react/components/shared/uikit/Button";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useDirectUpload } from "@circle-react/hooks/useDirectUpload";
import { useEmojiPickerContext } from "../../../EmojiPickerContext";
import { ALLOWED_FILE_TYPES } from "../constants";

interface LinkUploaderProps {
  close: () => void;
}

export const LinkUploader = ({ close }: LinkUploaderProps) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState<string | null | undefined>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    handleCustomIconUpload,
    shouldCloseOnSelect,
    setIsCustomFileUploadProcessing,
  } = useEmojiPickerContext();

  const fileLinkInputRef = useRef<HTMLInputElement>(null);

  const { uploadFile, status, isError, response, progress } = useDirectUpload();

  const isUploadingImage = !isError && status === "uploading";

  const isValidImage = (type: any) => ALLOWED_FILE_TYPES.includes(type);

  const fetchFromRemoteOriginAndReturnUrl = async ({
    url,
    id = uniqueId(),
  }: any) => {
    setIsProcessing(true);
    const res = await fetch(url);
    const blob = await res.blob();

    if (isValidImage(res.headers.get("content-type"))) {
      const file = new File([blob], id, {
        type: "image/jpeg",
      });
      uploadFile(file);
      setImageError(null);
    } else {
      setImageError(t("emoji_picker_v3.custom_icon.link_upload.error"));
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    isFunction(handleCustomIconUpload) &&
      handleCustomIconUpload({ status, progress, response, isError });

    if (status === "uploading") {
      setIsCustomFileUploadProcessing(true);
    }

    if (status === "completed" && response && shouldCloseOnSelect) {
      setIsCustomFileUploadProcessing(false);
      setTimeout(() => {
        close();
      }, 1000);
    }

    if (status === "completed" && response && shouldCloseOnSelect) close();
  }, [status, isError, response, progress]);

  useLayoutEffect(() => {
    fileLinkInputRef.current?.focus();
  }, []);

  const LINK_UPLOADER_BUTTON_CTA_MAPPER = {
    [t("emoji_picker_v3.custom_icon.cta_uploading")]: isUploadingImage,
    [t("emoji_picker_v3.custom_icon.link_upload.cta_processing")]: isProcessing,
    [t("emoji_picker_v3.custom_icon.cta")]: true,
  };

  return (
    <div className="space-y-2">
      <div className="flex w-full flex-row">
        <input
          placeholder={t("emoji_picker_v3.custom_icon.link_upload.placeholder")}
          type="url"
          value={imageUrl}
          onChange={e => setImageUrl(e.target.value)}
          ref={fileLinkInputRef}
          className="border-hover focus:!border-darkest hover:border-darkest form-control placeholder:text-light !rounded-r-none border border-r-0 !py-2 !text-sm placeholder:opacity-100 focus:border"
        />
        <Button
          variant="secondary-light"
          className="rounded-l-none"
          type="button"
          onClick={() => fetchFromRemoteOriginAndReturnUrl({ url: imageUrl })}
        >
          {Object.keys(LINK_UPLOADER_BUTTON_CTA_MAPPER).find(
            key => LINK_UPLOADER_BUTTON_CTA_MAPPER[key],
          )}
        </Button>
      </div>
      {(imageError || isError) && (
        <Typography.LabelXs>
          {imageError}
          {isError && response?.errors?.[0]?.detail}
        </Typography.LabelXs>
      )}
    </div>
  );
};
