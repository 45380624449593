import { forwardRef } from "react";
import { t } from "i18n-js";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import type { IconButtonProps } from "@circle-react-uikit/HeaderV3/IconButton";

export interface ModalCloseButtonProps
  extends Omit<IconButtonProps, "name" | "ariaLabel"> {
  className?: string;
}

export const ModalCloseButton = forwardRef<
  HTMLButtonElement,
  ModalCloseButtonProps
>(({ onClick, className = "", ...rest }, ref) => (
  <IconButton
    ref={ref}
    name="20-close"
    onClick={onClick}
    buttonSize={28}
    iconSize={20}
    variant="primary"
    iconClassName="!text-dark"
    ariaLabel={t("close")}
    className={className}
    data-testid="modal-close-button"
    {...rest}
  />
));

ModalCloseButton.displayName = "ModalCloseButton";
