import type { ReactNode } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export interface ItemLabelProps {
  description?: ReactNode;
  emptyLabel?: boolean;
  hideDescription?: boolean;
  id?: string; // TODO: Make it required
  isDisabled?: boolean;
  label?: ReactNode;
  labelClassName?: string;
  labelWrapperClassName?: string;
  name?: string;
  required?: boolean;
  shouldToggleValueOnLabelClick?: boolean;
  onToggleValue?: () => void;
  tooltipText?: string;
  variant?: string;
}

export const ItemLabel = ({
  description = "",
  emptyLabel = false,
  hideDescription = false,
  id,
  isDisabled = false,
  label,
  labelClassName = "",
  labelWrapperClassName = "",
  name = "",
  required = false,
  shouldToggleValueOnLabelClick = false,
  onToggleValue,
  tooltipText = "",
  variant = "default",
}: ItemLabelProps) => {
  const { setValue, getValues } = useFormContext();

  const onLabelToggleValueClick = () => {
    if (!shouldToggleValueOnLabelClick || isDisabled) {
      return;
    }

    const hasValue = Boolean(getValues(name));
    setValue(name, !hasValue, { shouldDirty: true });
    onToggleValue?.();
  };

  return (
    <div
      className={classnames(
        `form-meta form-meta--${variant}`,
        labelWrapperClassName,
      )}
    >
      <label
        className={classnames(
          "form-group__label text-dark flex font-semibold",
          labelClassName,
          {
            "cursor-not-allowed opacity-20": isDisabled,
          },
        )}
        data-testid="item-label"
        {...(shouldToggleValueOnLabelClick && {
          onClick: onLabelToggleValueClick,
        })}
        htmlFor={id}
      >
        {label ? (
          <span>
            {label}
            {required && (
              <TippyV2 content={t("required_field")}>
                <span className="text-red-500">*</span>
              </TippyV2>
            )}
            {Boolean(tooltipText) && (
              <span className="ml-2 inline-flex">
                <TippyV2 content={tooltipText}>
                  <Icon type="question-mark" className="icon icon--xs" />
                </TippyV2>
              </span>
            )}
          </span>
        ) : (
          emptyLabel && <>&nbsp;</>
        )}
      </label>
      {description && !hideDescription && (
        <div
          className={classnames("field-description", {
            "cursor-not-allowed opacity-20": isDisabled,
          })}
        >
          {description}
        </div>
      )}
    </div>
  );
};
