import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useEmojiPickerContext } from "../EmojiPickerContext";
import { Tab } from "./Tab";

export const Header = () => {
  const {
    activeTab,
    setActiveTab,
    onRemoveEmoji,
    EMOJI_PICKER_ENABLED_TABS_MAPPER,
  } = useEmojiPickerContext();

  const TAB_ITEMS = [
    {
      label: t("emoji_picker_v3.tabs.emoji"),
      value: "emoji",
    },
    {
      label: t("emoji_picker_v3.tabs.shapes"),
      value: "shapes",
    },
    {
      label: t("emoji_picker_v3.tabs.icon"),
      value: "icons",
    },
    {
      label: t("emoji_picker_v3.tabs.custom"),
      value: "custom",
    },
  ];

  const enabledTabs = TAB_ITEMS.filter(
    ({ value }) => EMOJI_PICKER_ENABLED_TABS_MAPPER[value],
  );

  if (enabledTabs.length <= 1) return null;

  return (
    <div
      className="border-primary space-x-6 border-b px-1"
      data-testid="emoji-picker-header"
    >
      <div className="flex flex-row items-center justify-between">
        <Tab>
          {TAB_ITEMS.map(({ label, value }) => {
            if (!EMOJI_PICKER_ENABLED_TABS_MAPPER[value]) return null;
            return (
              <Tab.Item
                key={value}
                label={label}
                isActive={value === activeTab.value}
                onClick={() => setActiveTab({ label, value })}
              />
            );
          })}
        </Tab>
        {isFunction(onRemoveEmoji) && (
          <Tab.Item
            label={t("emoji_picker_v3.tabs.remove")}
            className="hover:text-dark"
            onClick={onRemoveEmoji}
          />
        )}
      </div>
    </div>
  );
};
