import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { get, isEqual, noop } from "lodash";
import { filterByLabel } from "./filters";
import { useSearchOptions } from "./useSearchOptions";

const findOptionByValue = (options, value, valuePath) =>
  options.find(option => get(option, valuePath) === value);

export const useSearchableSelect = ({
  options = [],
  onChange = noop,
  isError = false,
  filterBy = filterByLabel,
  placeholder = t("select_an_option"),
  displayValue = option => option?.label || "",
  onChangeValue = option => option?.value || "",
  onSearchChange = noop,
  groupByValue = null,
  value,
  initialValue = "",
  initialValuePath = "value",
  optionsClassName,
}) => {
  const hasInitialValue = Boolean(initialValue) && Boolean(initialValuePath);
  const initialOption =
    (hasInitialValue &&
      findOptionByValue(options, initialValue, initialValuePath)) ||
    null;

  const [currentOption, setCurrentOption] = useState(initialOption);
  const clearValue = () => setCurrentOption(null);

  const {
    clearSearch,
    handleSearchChange,
    searchResults,
    hasNoResults,
    groupedSearchResults,
    hasGroups,
  } = useSearchOptions(options, filterBy, groupByValue, onSearchChange);

  const selectProps = {
    value: currentOption,
    onFilter: handleSearchChange,
    onClose: clearSearch,
    onChange: option => {
      if (isEqual(value, get(option, initialValuePath))) return;

      setCurrentOption(option);
      onChange(onChangeValue(option));
    },
    placeholder,
    displayValue,
    isError,
    optionsClassName,
  };

  const dropdownProps = {
    ...selectProps,
    nullable: true,
    onClose: () => {
      clearValue();
      clearSearch();
    },
  };

  useEffect(() => {
    if (!initialValue) {
      clearValue();
    }
  }, [initialValue]);

  useEffect(() => {
    if (get(currentOption, initialValuePath) === value) return;

    const foundOption = findOptionByValue(options, value, initialValuePath);
    setCurrentOption(foundOption);
  }, [currentOption, initialValuePath, options, value]);

  return {
    selectProps,
    dropdownProps,
    searchResults,
    hasNoResults,
    groupedSearchResults,
    hasGroups,
  };
};
