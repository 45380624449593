import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";
import { noop } from "lodash";
import type { ControllerProps, FieldValues } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { TextSelect } from "@circle-react-shared/TextSelect";
import { Typography } from "@circle-react-uikit/Typography";

type TextSelectProps = ComponentPropsWithoutRef<typeof TextSelect>;

interface FormTextSelectProps extends TextSelectProps {
  name: string;
  onChange?: (value: string) => void;
  label: string | ReactNode;
  rules?: ControllerProps<FieldValues, any>["rules"];
  wrapperClassName?: string;
}

export const FormTextSelect = ({
  name,
  onChange = noop,
  label,
  rules = {},
  wrapperClassName,
  initialValue,
  ...rest
}: FormTextSelectProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message;
        const hasError = Boolean(errorMessage);

        return (
          <div
            className={classNames(
              "flex flex-col gap-1.5",
              {
                "text-v2-danger": hasError,
              },
              wrapperClassName,
            )}
          >
            <TextSelect
              initialValue={initialValue}
              value={field.value}
              label={label}
              isError={hasError}
              onChange={value => {
                field.onChange(value);
                onChange?.(value);

                setValue(name, value, {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              {...rest}
            />
            {hasError && (
              <Typography.LabelXs>
                <span
                  className={classNames({
                    "text-v2-danger": hasError,
                  })}
                >
                  {errorMessage}
                </span>
              </Typography.LabelXs>
            )}
          </div>
        );
      }}
    />
  );
};

FormTextSelect.displayName = "Form.TextSelect";
