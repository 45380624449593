import PropTypes from "prop-types";
import { t } from "i18n-js";
import { noop } from "lodash";
import { Combobox } from "@circle-react-uikit/Combobox";

const SearchableSelectDropdown = ({
  children,
  displayValue = option => option?.label || "",
  onChange = noop,
  onClose = noop,
  onFilter = noop,
  placeholder = t("select_an_option"),
  value = null,
  buttonRef = null,
  button,
  optionsClassName,
}) => (
  <Combobox value={value} onChange={onChange}>
    <Combobox.Button ref={buttonRef}>{button}</Combobox.Button>
    <Combobox.Options onClose={onClose} className={optionsClassName}>
      <Combobox.Input
        displayValue={displayValue}
        onChange={event => onFilter(event.target.value)}
        placeholder={placeholder}
      />
      <hr className="border-primary" />
      <Combobox.OptionsScrollableContainer>
        {children}
      </Combobox.OptionsScrollableContainer>
    </Combobox.Options>
  </Combobox>
);

SearchableSelectDropdown.propTypes = {
  children: PropTypes.node,
  displayValue: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  button: PropTypes.node,
  buttonRef: PropTypes.object,
};

SearchableSelectDropdown.EmptyOption = Combobox.EmptyOption;
SearchableSelectDropdown.Option = Combobox.Option;

export { SearchableSelectDropdown };
