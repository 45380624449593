import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import type { IconType } from "@circle-react/components/shared/Icon/IconNames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";

export interface IconButtonProps
  extends Omit<ComponentPropsWithRef<"button">, "onClick"> {
  ariaLabel: string;
  buttonSize?: number;
  dataTestId?: string;
  iconClassName?: string;
  iconSize?: number;
  name: IconType;
  onClick?: () => void | Promise<void> | Promise<unknown>;
  variant?: "primary" | "secondary";
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      type = "button",
      name,
      onClick,
      className,
      iconClassName,
      iconSize = 16,
      variant: variantProp,
      buttonSize = 24,
      ariaLabel,
      disabled = false,
      dataTestId,
      ...rest
    },
    ref,
  ) => {
    const { isV3Enabled } = useIsV3();
    const variant = variantProp ?? (isV3Enabled ? "secondary" : "primary");

    return (
      <button
        // eslint-disable-next-line react/button-has-type -- type is set to button by default
        type={type}
        disabled={disabled}
        className={classNames(
          "flex justify-center rounded p-1 transition-colors duration-200",
          {
            "hover:bg-secondary focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2":
              variant === "primary",
            "hover:bg-tertiary focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2":
              variant === "secondary",
          },
          {
            "h-6 w-6": buttonSize === 24,
            "h-7 w-7": buttonSize === 28,
            "h-8 w-8": buttonSize === 32,
            "h-9 w-9": buttonSize === 36,
          },
          className,
        )}
        ref={ref}
        onClick={async event => {
          if (isFunction(onClick)) {
            event.stopPropagation();
            event.preventDefault();
            await onClick();
          }
        }}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        {...rest}
      >
        <Icon
          type={name}
          size={iconSize}
          className={classNames("text-default", iconClassName, {
            "h-4 w-4": iconSize === 16,
            "h-5 w-5": iconSize === 20,
            "h-6 w-6": iconSize === 24,
            "h-7 w-7": iconSize === 28,
            "h-8 w-8": iconSize === 32,
            "h-9 w-9": iconSize === 36,
          })}
          useWithFillCurrentColor
        />
      </button>
    );
  },
);

IconButton.displayName = "IconButton";
