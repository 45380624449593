import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isString, noop } from "lodash";
import { DEPRECATED_isValidURL } from "@circle-react/helpers/urlHelpers/isValidUrl";
import { Button } from "@circle-react-uikit/Button";

export const ImagePreview = ({ reset, value, onRemove = noop }) => {
  let imageURL;
  if (isString(value) && DEPRECATED_isValidURL(value)) {
    imageURL = value;
  } else {
    imageURL = URL.createObjectURL(value);
  }

  const handleRemove = async () => {
    await onRemove();
    reset();
  };
  return (
    <div className="upload-preview">
      <img loading="lazy" src={imageURL} alt={t("og_image")} />
      <div className="upload-preview__actions">
        <Button onClick={handleRemove} variant="secondary">
          {I18n.t("remove")}
        </Button>
      </div>
    </div>
  );
};

ImagePreview.propTypes = {
  reset: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRemove: PropTypes.func,
};
