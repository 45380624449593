import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { noop } from "lodash";
import type { RegisterOptions } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

export interface ToggleSwitchProps extends ComponentPropsWithoutRef<"input"> {
  name: string;
  id?: string;
  rules?: RegisterOptions;
  onToggleChange?: (checked: boolean) => void;
  wrapperClass?: string;
  variant?: "small" | "large" | "default";
  shouldUseCheckmark?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
}

export const ToggleSwitch = ({
  name,
  id = "",
  rules = {},
  onToggleChange = noop,
  wrapperClass = "",
  variant = "default",
  shouldUseCheckmark = true,
  className = "",
  disabled = false,
  label = t("toggle_switch"),
  ...rest
}: ToggleSwitchProps) => {
  const { register } = useFormContext();
  const idAttribute = id ? id : `checkbox-${name}`;
  const hasCheckmark = shouldUseCheckmark && variant === "small";

  const inputProps = register(name, {
    onChange(event) {
      onToggleChange(event.target.checked);
    },
    ...rules,
  });

  return (
    <div
      className={classNames(
        `toggle-switch toggle-switch--${variant} relative max-w-[1.9rem]`,
        wrapperClass,
      )}
      id={`field-${name}`}
    >
      <input
        {...rest}
        className={classNames("peer", className)}
        type="checkbox"
        disabled={disabled}
        id={idAttribute}
        data-testid="item-checkbox"
        {...inputProps}
      />
      <label
        htmlFor={idAttribute}
        className="bg-toggle-unchecked before:bg-toggle-circle-unchecked peer-checked:bg-toggle-checked before:peer-checked:bg-toggle-circle-checked relative"
      >
        <span className="sr-only">{label}</span>
      </label>
      {hasCheckmark && (
        <span
          className={classNames(
            "text-darkest pointer-events-none absolute right-1 top-1 hidden items-center opacity-0 transition-opacity peer-checked:flex peer-checked:opacity-100",
            {
              "!opacity-20": disabled,
            },
          )}
        >
          <Icon type="12-circle-check" size={12} className="!h-3 !w-3" />
        </span>
      )}
    </div>
  );
};

ToggleSwitch.displayName = "Form.ToggleSwitch";
