import { forwardRef } from "react";
import type { MutableRefObject } from "react";
import type { ComponentPropsWithRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { ModalDataProvider } from "./ModalDataProvider";
import { ModalLevelContext } from "./ModalLevelContext";
import { ModalNestingContext } from "./ModalNestingContext";

export interface ModalRootProps extends ComponentPropsWithRef<"div"> {
  title?: string;
  isOpen: boolean;
  onClose?: () => void | Promise<void> | Promise<unknown>;
  contentPortalId?: string;
  shouldShowCloseButton?: boolean;
  className?: string;
  initialFocus?: MutableRefObject<HTMLElement | null>;
}

export const ModalRoot = forwardRef<HTMLDivElement, ModalRootProps>(
  (
    {
      children,
      className = "",
      isOpen = false,
      onClose,
      contentPortalId = "",
      shouldShowCloseButton = false,
      title,
      ...rest
    },
    ref,
  ) => (
    <Transition
      as={Dialog}
      show={isOpen}
      // @ts-expect-error - Transition props not working when using `as={Dialog}`
      onClose={async () => {
        if (!isFunction(onClose)) return;
        await onClose();
      }}
      title={title}
      className={classNames("fixed inset-0 z-[1060]", className)}
      ref={ref}
      {...rest}
    >
      <ModalNestingContext>
        <ModalLevelContext>
          <ModalDataProvider
            isInsideModal
            contentPortalId={contentPortalId}
            onClose={onClose}
            shouldShowCloseButton={shouldShowCloseButton}
            isOpen={isOpen}
          >
            {children}
          </ModalDataProvider>
        </ModalLevelContext>
      </ModalNestingContext>
    </Transition>
  ),
);

ModalRoot.displayName = "ModalRoot";
