import { t } from "i18n-js";
import { FileUploader } from "./FileUploader";
import { LinkUploader } from "./LinkUploader";

export interface CustomPickerProps {
  close: () => void;
}

export const CustomPicker = ({ close }: CustomPickerProps) => (
  <div className="flex flex-col space-y-4">
    <LinkUploader close={close} />
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className="border-primary w-full border-t" />
      </div>
      <div className="relative flex justify-center text-sm">
        <span className="bg-primary text-default px-2">
          {t("emoji_picker_v3.custom_icon.or")}
        </span>
      </div>
    </div>
    <FileUploader close={close} />
  </div>
);

export default CustomPicker;
