import { forwardRef } from "react";
import type { ReactNode } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";

export interface ModalFooterProps extends ComponentPropsWithRef<"div"> {
  className?: string;
  children: ReactNode;
}

export const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>(
  ({ children, className = "", ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames("sticky px-8 py-6", className)}
      data-testid="modal-footer"
      {...rest}
    >
      {children}
    </div>
  ),
);

ModalFooter.displayName = "ModalFooter";
